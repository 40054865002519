import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'
import './styles.scss'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const CallToAction = ({ backgroundColour, titleArea, content, addCta, callToAction, utm }) => {
    return (
        <Section variant={backgroundColour} titleArea={titleArea} className={'c-call-to-action'}>
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    {content && parse(content)}

                    {addCta && (
                        <CtaContainer align={'centered'}>
                            <ButtonLink data={callToAction} size={'lg'} icon={'arrow'} utm={utm} />
                        </CtaContainer>
                    )}
                </div>
            </div>
        </Section>
    )
}

CallToAction.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        titlePosition: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string
    }),
    content: PropTypes.string,
    addCta: PropTypes.bool,
    callToAction: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        target: PropTypes.string
    }),
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

CallToAction.defaultProps = {
    backgroundColour: 'gradient',
    titleArea: {
        addTitleArea: true,
        titlePosition: 'centered',
        title: 'Section Title'
    },
    content: '<p>Content goes here</p>',
    addCta: false,
    callToAction: {
        url: '#',
        title: 'Button Title'
    }
}

export default CallToAction

export const query = graphql`
    fragment CallToActionForPageBuilder on WpPage_Acfpagebuilder_Layouts_CallToAction {
        fieldGroupName
        backgroundColour
        addTitleArea
        title
        description
        content
        addCta
        callToAction {
            target
            title
            url
        }
    }
`
